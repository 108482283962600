import React from 'react'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import PageHeading from '../UI/PageHeading/PageHeading'
import { Row } from '../UI/Layouts/Layouts'
import { Text, Title } from './../UI/Typing'
import Blank from '../UI/Blank/Blank'
import rectBlank from '../Assets/rectBlank.png'
import rectHBlank from '../Assets/rect.png'
import Animotion from '../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Parallax } from 'react-scroll-parallax'
import { RoomButton } from '../UI/Button/Button'
import './rooms.styl'

// import images
import HeadImg from './../Assets/images_pg/territorio/castello1.jpg'
import Room1m from './../Assets/images_pg/territorio/oslavia2.jpg'
import Room1s from './../Assets/images_pg/territorio/oslavia.jpg'
import Room2m from './../Assets/images_pg/territorio/sistiana.jpg'
import Room2s from './../Assets/images_pg/territorio/aquileia.jpg'
import Room3m from './../Assets/images_pg/territorio/isonzo.jpg'
import Room3s from './../Assets/images_pg/territorio/vigne.jpg'
import Room4m from './../Assets/images_pg/territorio/redipuglia1.jpg'
import Room4s from './../Assets/images_pg/territorio/redipuglia3.jpg'
import Room5m from './../Assets/images_pg/territorio/coronini1.jpg'
import Room5s from './../Assets/images_pg/territorio/coronini5.jpg'
import Room6m from './../Assets/images_pg/territorio/coronini3.jpg'
import Room6s from './../Assets/images_pg/territorio/coronini4.jpg'

const Line = () => <span className='Line' />

const RoomRow = props => (
  <div className={`RoomRow ${props.position}`}>
    <Row>
      <div className='RoomImg'>
        <Blank sizer={rectBlank} image={props.image} />
      </div>
      <div className='RoomDesc'>
        <Blank sizer={rectBlank} image={props.subimage} />
        <RoomInfo {...props} />
      </div>
    </Row>
  </div>
)

const RoomColumn = props => (
  <div className={`RoomColumn ${props.position}`}>
    <div>
      <div style={{ zIndex: 3, position: 'relative' }}>
        <Parallax offsetYMax={20} offsetYMin={-20}>
          <Blank sizer={rectHBlank} image={props.image} />
        </Parallax>
      </div>
      <div>
        <div>
          <Parallax slowerScrollRate offsetYMax={13} offsetYMin={-13}>
            <Blank sizer={rectBlank} image={props.subimage} />
          </Parallax>
        </div>
        <RoomInfo {...props} />
      </div>
    </div>
  </div>
)

const RoomInfo = props => (
  <aside>
    <Animotion>
      <Title>{props.title}</Title>
      <Line />
      <Text>{props.description}</Text>
      {props.externalLink && (
        <RoomButton to={props.externalLink}>
          <FormattedMessage id='goToLink' />
        </RoomButton>
      )}
    </Animotion>
  </aside>
)

const RoomPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        title={<FormattedMessage id='poi' />}
        subtitle={<FormattedMessage id='poiSubtitle' />}
        text={<FormattedMessage id='poiDescription' />}
        image={HeadImg}
      />
      <section className='Rooms'>
        <Animotion>
          <RoomRow
            title={<FormattedMessage id='poi1Title' />}
            description={<FormattedMessage id='poi1Description' />}
            position='left'
            image={Room3m}
            subimage={Room3s}
            externalLink={'https://www.turismofvg.it/'}
          />
        </Animotion>

        <Animotion>
          <RoomColumn
            position='left'
            title={<FormattedMessage id='poi2Title' />}
            description={<FormattedMessage id='poi2Description' />}
            image={Room2m}
            subimage={Room2s}
            externalLink={
              'https://www.turismofvg.it/Grado-e-Aquileia?UrlBack=aHR0cHM6Ly93d3cudHVyaXNtb2Z2Zy5pdC9BcmVlLXR1cmlzdGljaGU%3D'
            }
          />
        </Animotion>
        <Animotion>
          <RoomColumn
            position='right'
            title={<FormattedMessage id='poi3Title' />}
            description={<FormattedMessage id='poi3Description' />}
            image={Room1m}
            subimage={Room1s}
            externalLink={
              'https://www.turismofvg.it/Museo-Della-Grande-Guerra-Di-Gorizia'
            }
          />
        </Animotion>
        <Animotion>
          <RoomRow
            position='right'
            title={<FormattedMessage id='poi4Title' />}
            description={<FormattedMessage id='poi4Description' />}
            image={Room4s}
            subimage={Room4m}
            externalLink={'https://www.turismofvg.it/Sacrario-Di-Redipuglia'}
          />
        </Animotion>
        <Animotion>
          <RoomColumn
            position='right'
            title={<FormattedMessage id='poi5Title' />}
            description={<FormattedMessage id='poi5Description' />}
            image={Room5m}
            subimage={Room5s}
            externalLink={'http://www.coronini.it/'}
          />
        </Animotion>
        <Animotion>
          <RoomColumn
            position='right'
            title={<FormattedMessage id='poi6Title' />}
            description={<FormattedMessage id='poi6Description' />}
            image={Room6m}
            subimage={Room6s}
            externalLink={'http://www.coronini.it/'}
          />
        </Animotion>
      </section>
    </PageTransition>
  </Layout>
)

export default RoomPage
